<template>
    <div class="ml-10 mr-10">
        <TabNav
            v-if="this.$route.params.uuid !== 'new'"
            :elements="[
                {text: this.$t('accounts.edit'),to: '/accounts/' + this.$route.params.uuid,},
                {text: this.$t('menu.transactions'),to: '/accounts/' + this.$route.params.uuid + '/transactions', active: true}
            ]"
        ></TabNav>

        <TitleBar
            v-if="this.$route.params.uuid !== 'new'"
            :disabled="loading"
            :loading="loading"
            :menu="[
                {text: this.$t('transactions.import'), callback: importTransactions},
            ]"
        >
            {{ $t("menu.transactions") }}
            <v-btn
                :to="'/accounts/' + $route.params.uuid + '/transactions/new'"
                class="mx-2"
                color="secondary"
                fab
                small
            >
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </TitleBar>

        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50, 100, -1]
            }"
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :loading="loading ? 'secondary' : false"
            :loading-text="$t('loading')"
            :no-data-text="$t('no_data')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1 mt-5"
        >

            <template
                v-slot:top
            >
                <div class="reload__btn">
                    <v-btn fab icon small text @click.prevent="fetchElements">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </div>

            </template>

            <template v-slot:item.transaction_date="{item}">
                <DateTime :date="item.transaction_date"></DateTime>
            </template>

            <template v-slot:item.amount="{item}">
                <span v-if="item.amount[0] === '-' || item.amount < 0"
                      class="red--text">{{ Utils.formatMoney(item.amount, currency, $i18n.locale) }}</span>
                <span v-else class="green--text">{{ Utils.formatMoney(item.amount, currency, $i18n.locale) }}</span>
            </template>

            <template v-slot:item.statement_descriptor="{item}">
                <router-link :to="'/accounts/' + $route.params.uuid + '/transactions/' + item.uuid">
                    {{ item.statement_descriptor }}
                </router-link>
            </template>

            <template v-slot:item.linked_to="{item}" style="max-width: 400px;">
                <TransactionLink :link_status="item.link_status" :linked_to="item.linked_to"
                                 :uuid="item.uuid"></TransactionLink>
            </template>

        </v-data-table>


        <v-divider class="mt-10 mb-5"></v-divider>

        <AccountConnection :account="this.$route.params.uuid"></AccountConnection>


        <v-dialog
            v-model="import_transactions"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    color="secondary"
                    dark
                >
                    <v-btn
                        dark
                        icon
                        @click="import_transactions = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ $t("transactions.import") }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!--                    <v-toolbar-items>-->
                    <!--                        <v-btn-->
                    <!--                            dark-->
                    <!--                            text-->
                    <!--                            @click="dialog = false"-->
                    <!--                        >-->
                    <!--                            Save-->
                    <!--                        </v-btn>-->
                    <!--                    </v-toolbar-items>-->
                </v-toolbar>
                <v-container class="mt-6">

                    <v-tabs
                        center-active
                        centered
                        color="secondary"
                    >
                        <v-tab>{{ $t("transactions.import_csv") }}</v-tab>
                        <v-tab>{{ $t("transactions.upload_file") }}</v-tab>

                        <v-tab-item class="mt-5">
                            <v-container fluid>
                                <p v-if="importing.loading">{{
                                        $t("csv.importing", {doing: importing.done, total: importing.total})
                                    }}</p>
                                <CsvImporter
                                    :headers="['date', 'statement', 'credit_amount', 'debit_amount', 'amount', 'id']"
                                    :loading="importing.loading" :use-all-headers="false"
                                    v-on:cancel="import_transactions = false"
                                    v-on:data="importCsvTransactions"></CsvImporter>
                            </v-container>
                        </v-tab-item>

                        <v-tab-item class="mt-5">
                            <v-container fluid>
                                <p>{{ $t("transactions.supported_file") }}</p>
                                <file-upload accept="*/*" v-on:file="filesChange"></file-upload>

                                <v-simple-table v-show="uploadedFiles.length > 0" class="mt-3">
                                    <template v-slot:default>
                                        <tbody>
                                        <tr
                                            v-for="file in uploadedFiles"
                                            :key="file.uuid"
                                        >
                                            <td>{{ truncate(file.name) }}</td>
                                            <td style="width: 94px;">
                                                <v-progress-linear
                                                    :color="file.color"
                                                    :indeterminate="file.indeterminate"
                                                    height="6"
                                                    rounded
                                                    value="100"

                                                ></v-progress-linear>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-container>
                        </v-tab-item>

                    </v-tabs>
                </v-container>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import TabNav from "@/components/TabNav.vue";
import TitleBar from "@/components/TitleBar.vue";
import AccountConnection from "@/views/Accounts/components/AccountConnection.vue";
import DateTime from "@/components/DateTime.vue";
import Utils from "../../../helpers/Utils";
import TransactionLink from "@/components/TransactionLink.vue";
import CsvImporter from "@/components/CsvImporter.vue";
import FileUpload from "@/components/FileUpload.vue";
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Transactions",
    computed: {
        Utils() {
            return Utils;
        }
    },
    components: {FileUpload, CsvImporter, TransactionLink, DateTime, AccountConnection, TitleBar, TabNav},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.accounts"),
                to: "/accounts",
                exact: true
            },
            {
                text: this.$t("accounts.edit"),
                to: "/accounts/" + this.$route.params.uuid,
                exact: true
            },
            {
                text: this.$t("menu.transactions"),
                to: "/accounts/" + this.$route.params.uuid + "/transactions",
                exact: false
            }
        ]);

        this.headers = [
            {text: this.$t("transactions.date"), value: "transaction_date", sortable: false},
            {text: this.$t("transactions.statement"), value: "statement_descriptor", sortable: false},
            {text: this.$t("transactions.amount"), value: "amount", sortable: false},
            {text: this.$t("transactions.link"), value: "linked_to", sortable: false}
        ];

        this.fetchElements();
    },
    methods: {
        truncate(str) {
            const MAX_LENGTH = 24;
            if (str.length <= MAX_LENGTH) {
                return str;
            }

            return str.substring(0, MAX_LENGTH) + "...";
        },
        filesChange(file) {
            let requestUUID = uuidv4();

            this.uploadedFiles.push({
                uuid: requestUUID,
                name: file.name,
                color: "primary",
                indeterminate: true
            });

            this.upload(requestUUID, file);
        },
        upload(requestUUID, file) {
            return new Promise(() => {
                let formData = new FormData();
                formData.append("file", file);

                let key = -1;
                for (let i = 0; i < this.uploadedFiles.length; i++) {
                    if (this.uploadedFiles[i].uuid === requestUUID) {
                        key = i;
                    }
                }

                formData.append("name", file.name);

                // Upload file
                Accounting.upload("/documents", formData)
                    .then((response) => {
                        let uuid = response.data.uuid;
                        this.uploadedFiles[key].color = "green";

                        Accounting.post("/transactions/import", {
                            account: this.$route.params.uuid,
                            document: uuid
                        }).then(() => {
                            this.uploadedFiles[key].indeterminate = false;
                            this.fetchElements();
                        }).catch(() => {
                            this.uploadedFiles[key].color = "red";
                            this.uploadedFiles[key].indeterminate = false;
                        });

                    })
                    .catch(() => {
                        this.uploadedFiles[key].color = "red";
                        this.uploadedFiles[key].indeterminate = false;
                    });
            });
        },
        importTransactions() {
            this.import_transactions = true;
        },
        async importCsvTransactions(data) {
            this.$store.commit("SET_ALERTS", []);
            this.loading = true;
            this.importing.loading = true;
            this.importing.total = data.length;

            let alerts = [];

            for (const datum of data) {
                await Accounting.post("/transactions/import", {
                    "account": this.$route.params.uuid,
                    "amount": datum.amount,
                    "credit_amount": datum.credit_amount,
                    "debit_amount": datum.debit_amount,
                    "statement_descriptor": datum.statement,
                    "transaction_date": datum.date,
                    "transaction_id": datum.id
                }).catch(error => {
                    if (error.response) {
                        for (const errorElement of error.response.data.errors) {
                            alerts.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                    } else if (error.request) {
                        alerts.push({
                            type: "error",
                            text: this.$t("api.no_response")
                        });
                    } else {
                        alerts.push({
                            type: "error",
                            text: error.message
                        });
                    }
                });
                this.importing.done++;
            }

            this.$store.commit("SET_ALERTS", alerts);

            this.loading = false;
            this.importing.loading = false;
            this.import_transactions = false;
            this.fetchElements();
        },
        fetchElements() {
            this.errors = [];
            this.loading = true;
            const {page, itemsPerPage} = this.options;

            this.items = [];
            this.totalItems = 0;

            const fields = ["uuid", "statement_descriptor", "transaction_date", "amount", "linked_to", "link_status"];

            Accounting.get("/accounts/" + this.$route.params.uuid + "/connect")
                .then(response => {
                    let data = response.data;
                    this.has_connections = data.total > 0;
                }).catch(() => {
            });
            Accounting.get("/accounts/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.currency = data.currency;
                })
                .catch(() => {
                });

            Accounting.get("/transactions?page=" + page + "&per_page=" + itemsPerPage + "&fields=" + fields.join(",") + "&account=" + this.$route.params.uuid)
                .then(response => {
                    let data = response.data;

                    this.totalItems = data.total;
                    this.items = data.transactions;

                    this.loading = false;
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });
        }
    },

    data() {
        return {
            tab: 1,
            loading: false,
            totalItems: 0,
            options: {},
            headers: [],
            items: [],
            errors: [],

            uploadedFiles: [],

            add_transaction: false,
            has_connections: false,
            currency: null,

            import_transactions: false,
            importing: {
                loading: false,
                done: 0,
                total: 0
            }
        };
    },
    watch: {
        tab(newValue) {
            if (!newValue) {
                this.$router.push("/accounts/" + this.$route.params.uuid);
            }
        },
        options: {
            handler() {
                this.fetchElements();
            },
            deep: true
        }
    }
};
</script>

<style>
.reload__btn {
    position: absolute;
    right: 42.5px;
    padding-top: 2.5px;
}

a {
    text-decoration: none;
}
</style>