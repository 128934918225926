<template>
    <div>
        <h3>
            {{ $t("transactions.bank_connections") }}
            <v-btn
                :loading="loading"
                class="mx-2"
                color="secondary"
                fab
                small
                @click="fetchBanks"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </h3>

        <v-row class="mt-3">
            <v-col v-for="connection in connections" :key="connection.uuid" cols="12" md="6">
                <v-card>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                {{ connection.bank.name }}
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                                {{ connection.iban }}
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-avatar
                            color="grey"
                            size="80"
                            tile
                        >
                            <v-img :src="connection.bank.logo"></v-img>
                        </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions>
                        <v-btn
                            icon
                            text
                            @click.prevent="deleteConnection(connection.uuid)"
                            :loading="loading"
                        >
                            <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>

                        <v-chip
                            v-if="connection.status === 'EXPIRED'"
                            class="ma-2"
                            color="red"
                            text-color="white"
                        >
                            {{ $t("transactions.connection_expired") }}
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    :loading="loading"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item link @click="listTransactions(connection.uuid)">
                                    <v-list-item-title>{{ $t("transactions.list_transactions") }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>


        <!--        Nordigen Bank Selection Dialog -->
        <v-dialog
            v-model="select_bank"
            max-width="600px"
            scrollable
        >
            <v-card :loading="loading">
                <v-card-title>{{ $t("transactions.select_bank") }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px; ">
                    <v-list
                        dense
                        nav
                    >
                        <template v-for="item in banks">


                            <v-list-item
                                :key="item.title"
                                @click.prevent="selectBank(item.id)"
                            >
                                <v-list-item-avatar>
                                    <v-img :src="item.logo"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="item.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="select_bank = false"
                    >
                        {{ $t("transactions.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Nordigen Account Selection Dialog -->
        <v-dialog
            v-model="select_account"
            max-width="600px"
            scrollable
        >
            <v-card :loading="loading">
                <v-card-title>{{ $t("transactions.select_account") }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px; ">
                    <v-list
                        dense
                        nav
                    >
                        <template v-for="item in accounts">
                            <v-list-item
                                :key="item.id"
                                @click.prevent="selectAccount(item.id)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-html="item.iban"></v-list-item-title>
                                    <v-list-item-subtitle v-html="item.bank.name"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="select_bank = false"
                    >
                        {{ $t("transactions.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Transaction list dialog -->
        <v-dialog
            v-model="transaction_dialog"
            max-width="600px"
            scrollable
        >
            <v-card :loading="loading">
                <v-card-title>{{ $t("transactions.transactions.list") }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px; ">
                    <v-list
                        flat
                        subheader
                        two-line
                    >
                        <template v-for="item in transactions">
                            <v-list-item
                                :key="item.id"
                            >
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="item.selected"
                                        color="secondary"
                                        :disabled="loading"
                                    ></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.amount }} &middot; {{ item.date }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.statement }}
                                        <v-chip v-if="imported_transactions.includes(item.id)" color="red">
                                            {{ $t("transactions.transactions.already_imported") }}
                                        </v-chip>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="transaction_dialog=false; imported_transactions=[]"
                    >
                        {{ $t("transactions.close") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="importTransactions"
                    >
                        {{ $t("transactions.transactions.import") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import Utils from "@/helpers/Utils";

export default {
    name: "AccountConnection",
    props: ["account"],
    data() {
        return {
            loading: false,

            select_bank: false,
            banks: [],
            select_account: false,
            accounts: [],
            connections: [],

            transactions: [],
            transaction_dialog: false,
            imported_transactions: []
        };
    },
    mounted() {
        this.fetchConnections();

        if (this.$route.query.ref !== undefined) {
            this.handleNordigenResponse();
        }
    },
    methods: {
        fetchConnections() {
            this.loading = true;
            Accounting.get("/accounts/" + this.account + "/connect")
                .then(response => {
                    // eslint-disable-next-line no-unused-vars
                    let data = response.data;

                    this.connections = data.connections;

                    this.loading = false;
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });
        },
        fetchBanks() {
            this.select_bank = true;
            this.loading = true;

            Accounting.get("/accounts/" + this.account + "/connect/banks")
                .then(response => {
                    let data = response.data;

                    this.banks = data.banks;

                    if (process.env.NODE_ENV !== "production") {
                        this.banks.push({
                            id: "SANDBOXFINANCE_SFIN0000",
                            name: "SANDBOX"
                        });
                    }

                    this.loading = false;
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

        },
        selectBank(id) {
            this.loading = true;
            Accounting.post("/accounts/" + this.account + "/connect/request", {
                redirect: window.location.href,
                bank: id
            }).then(response => {
                let data = response.data;
                window.location = data.redirect;
            }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        },
        selectAccount(id) {
            this.loading = true;
            Accounting.post("/accounts/" + this.account + "/connect/accounts", {
                connection: this.$route.query.ref,
                account: id
            }).then(() => {
                this.select_account = false;
                this.loading = false;
                this.fetchConnections();
                this.$router.push("/accounts/" + this.account + "/transactions");
            }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        },
        handleNordigenResponse(retry = 0) { // Retry system as the page take a certain time to load...
            if (retry > 3) {
                return;
            }

            setTimeout(() => {
                if (this.$store.state.API_TOKEN == null) {
                    this.handleNordigenResponse(retry + 1);
                    return;
                }

                if (this.$route.query.error !== undefined) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: "Error connecting to your bank account. Please try again."
                    }]);
                    return;
                }

                // Show account selection UI
                this.loading = true;
                this.select_account = true;
                Accounting.get("/accounts/" + this.account + "/connect/accounts?connection=" + this.$route.query.ref)
                    .then(response => {
                        let data = response.data;
                        this.accounts = data.accounts;
                        this.loading = false;
                    })
                    .catch(error => {
                        if (error.response) {
                            let errors = [];
                            for (const errorElement of error.response.data.errors) {
                                errors.push({
                                    type: "error",
                                    text: errorElement
                                });
                            }
                            this.$store.commit("SET_ALERTS", errors);
                        } else if (error.request) {
                            this.$store.commit("SET_ALERTS", [{
                                type: "error",
                                text: this.$t("api.no_response")
                            }]);
                        } else {
                            this.$store.commit("SET_ALERTS", [{
                                type: "error",
                                text: error.message
                            }]);
                        }
                        this.loading = false;
                    });

            }, 3000);
        },
        deleteConnection(uuid) {
            this.$swal({
                title: this.$t("transactions.connection.delete_title"),
                text: this.$t("transactions.connection.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("transactions.connection.delete_yes"),
                cancelButtonText: this.$t("transactions.connection.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/accounts/" + this.account + "/connect/" + uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("transactions.connection.deleted")
                            }]);
                            this.loading = false;

                            for (const connectionsKey in this.connections) {
                                if (this.connections[connectionsKey]["uuid"] === uuid) {
                                    this.connections.splice(connectionsKey, 1);
                                }
                            }

                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        listTransactions(uuid) {
            this.loading = true;

            Accounting.get("/transactions?account=" + this.account + "&fields=transaction_id&per_page=-1&page=1")
                .then(resp => {
                    this.imported_transactions = [];
                    for (const transaction of resp.data.transactions) {
                        this.imported_transactions.push(transaction["transaction_id"]);
                    }
                })
                .catch(() => {
                });

            Accounting.get("/accounts/" + this.account + "/connect/" + uuid + "?period=90")
                .then(resp => {
                    this.transactions = [];

                    for (const transaction of resp.data.transactions) {
                        transaction.selected = false;
                        this.transactions.push(transaction);
                    }

                    this.transaction_dialog = true;
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async importTransactions() {
            this.loading = true;
            this.$store.commit("SET_ALERTS", []);
            for (const transaction of this.transactions) {
                if (transaction.selected) {
                    await Accounting.post("/transactions", {
                            account: this.account,
                            amount: transaction.amount,
                            statement_descriptor: transaction.statement ? transaction.statement : "-",
                            transaction_date: transaction.date,
                            transaction_id: transaction.id
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = this.$store.state.alerts;
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                let errors = this.$store.state.alerts;
                                errors.push({
                                    type: "error",
                                    text: this.$t("api.no_response")
                                });
                                this.$store.commit("SET_ALERTS", errors);
                            } else {
                                let errors = this.$store.state.alerts;
                                errors.push({
                                    type: "error",
                                    text: error.message
                                });
                                this.$store.commit("SET_ALERTS", errors);
                            }
                        });
                }
            }
            this.loading = false;
            this.transaction_dialog = false;
            let errors = this.$store.state.alerts;
            errors.push({
                type: "success",
                text: this.$t("transactions.transactions.imported")
            });
            this.$store.commit("SET_ALERTS", errors);
        }
    }
};
</script>

<style scoped>

</style>