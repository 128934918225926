<template>
    <div>
        <div v-if="loading">
            <v-progress-linear color="accent" indeterminate rounded style="width: 50px;"></v-progress-linear>
        </div>
        <div v-else-if="errors.length > 0" class="red--text">
            {{ errors.join(", ") }}
            <v-icon class="ml-3" @click="errors = []">mdi-close-circle</v-icon>
        </div>
        <div v-else-if="link_status === 'confirmed'" style="display: flex;gap: 0 10px;">
            <v-icon>mdi-check</v-icon>
            <DynamicElement :type="getLinkType()" :uuid="getLinkUuid()"></DynamicElement>
        </div>
        <div v-else-if="linked_to === null">
            <v-autocomplete
                v-model="select"
                :items="items"
                :loading="loadingSearch"
                :readonly="loadingSearch"
                :search-input.sync="query"

                class="list__field"

                dense
                filled

                hide-details

                item-text="name"
                no-filter
                prepend-inner-icon="mdi-magnify"

                return-object
            >

                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>{{ iconFor(item.type) }}</v-icon>
                        <v-list-item-action-text
                            v-text="$t('search.type.' + item.type)"></v-list-item-action-text>
                    </v-list-item-action>
                </template>

            </v-autocomplete>
        </div>
        <div v-else style="display: flex;gap: 0 10px;">
            <v-icon>mdi-link</v-icon>
            <DynamicElement :type="getLinkType()" :uuid="getLinkUuid()"></DynamicElement>
            <v-btn class="ml-5" color="green" icon x-small @click="confirmLinkage">
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn class="ml-2" color="red" icon x-small @click="removeLinkage">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import DynamicElement from "@/components/DynamicElement.vue";
import Accounting from "@/helpers/Accounting";

export default {
    name: "TransactionLink",
    components: {DynamicElement},
    props: ["uuid", "linked_to", "link_status"],
    data() {
        return {
            loading: false,
            loadingSearch: false,
            select: null,
            items: [],
            query: null,
            errors: []
        };
    },
    watch: {
        query(val) {
            val && val !== this.model && this.search();
        },
        select(val) {
            if (val != null) {
                this.linked_to = val.type + ":" + val.uuid;
                this.updateTransaction("unconfirmed");
            }
        }
    },
    methods: {
        removeLinkage() {
            this.select = null;
            this.linked_to = null;
            this.updateTransaction("unconfirmed");
        },
        confirmLinkage() {
            this.link_status = "confirmed";
            this.updateTransaction("confirmed");
        },
        updateTransaction(status) {
            this.loading = true;

            Accounting.get("/transactions/" + this.uuid)
                .then(resp => {
                    const data = resp.data;
                    Accounting.post("/transactions/" + this.uuid, {
                            account: data.account,
                            amount: data.amount,
                            link_status: status,
                            linked_to: this.linked_to,
                            statement_descriptor: data.statement_descriptor,
                            transaction_date: data.transaction_date,
                            transaction_id: data.transaction_id
                        })
                        .then(() => {
                        })
                        .catch(error => {
                            if (error.response) {
                                this.errors = error.response.data.errors;
                            } else if (error.request) {
                                this.errors = [this.$t("api.no_response")];
                            } else {
                                this.errors = [error.message];
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        getLinkUuid() {
            if (this.linked_to === null || !this.linked_to.includes(":")) {
                return null;
            }
            return this.linked_to.split(":")[1];
        },
        getLinkType() {
            if (this.linked_to === null || !this.linked_to.includes(":")) {
                return null;
            }
            return this.linked_to.split(":")[0];
        },
        search() {
            if (this.query === null || this.query === "") {
                return;
            }

            this.loadingSearch = true;
            Accounting.get("/search?only=bill,invoice,payment,revenue,transfer&q=" + encodeURI(this.query))
                .then(response => {
                    let data = response.data;
                    this.items = data.hits;
                    this.loadingSearch = false;
                })
                .catch(() => {
                    this.loadingSearch = false;
                });
        },
        iconFor(type) {
            const icons = {
                "bill": "mdi-receipt-text",
                "invoice": "mdi-receipt-text",
                "customer": "mdi-account",
                "contact": "mdi-account",
                "account": "mdi-bank",
                "category": "mdi-tag",
                "document": "mdi-file-document-outline",
                "quote": "mdi-receipt-text",
                "transaction": "mdi-bank-transfer",
                "vendor": "mdi-account",
                "expense_report": "mdi-receipt-send",
                "credential": "mdi-shield-key",
                "payment": "mdi-wallet"
            };
            return icons[type] !== undefined ? icons[type] : "mdi-help-circle-outline";
        }
    }
};
</script>

<style scoped>
.list__field {
    margin-bottom: 2px;
}
</style>